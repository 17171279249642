$primary: #29262c;

.formContainer {
  background-color: $primary;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .formWrapper {
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    min-width: 300px;
  }

  .title {
    color: #5d5b8d;
    font-size: 12px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    input {
      padding: 15px;
      border: none;  
      border-bottom: 1px solid $primary;

      &::placeholder {
        color: rgb(175, 175, 175);
      }
    }

    button {
      background-color: $primary;
      color: white;
      padding: 10px;
      font-weight: bold;
      border: none;
      cursor: pointer;
    }

    label {
      display: flex;
      align-items: center;
      gap: 10px;
      color: $primary;
      font-size: 12px;
      cursor: pointer;

      img {
        width: 32px;
      }
    }
  }

  p {
    color: #5d5b8d;
    font-size: 12px;
    margin-top: 10px;
  }
}