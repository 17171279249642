@mixin mobile {
  @media screen and (max-width: 560px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

* {
  scroll-behavior: smooth;
}

$primary: #7b4bab;
$secondary: #ffffff;

// $chatNavbarBanner1: #242424;
$chatNavbarBanner1: #373737;
$chatNavbarBanner2: #1b1b1b;
// $chatNavbarBanner2: #0d0d0d;
$chatNavbarSidePanel: #383749;

.home {
  background-color: $secondary;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 95%;
    height: 95vh;
    display: flex;
    overflow: hidden;

    .more {
      display: none;
    }

    @include mobile() {
      width: 95%;
      height: 95vh;
      position: relative;

      .more {
        display: block;
        right: 16px;
        top: -56px;
        position: absolute;
        z-index: 100;
      }

      .hide {
        display: none;
        height: 100vh;
      }


      .sidebar {
        position: absolute !important;
        top: 65px;
        width: 100%;
        z-index: 1;

        .navbar {
          width: 95%;

          .user {
            position: relative;
            width: 100%;

            button {
              position: absolute;
              bottom: -2px !important;
              border-radius: 5px;
              display: flex;
              padding: 8px;
              right: 0px;
            }
          }
        }
      }

      .search,
      .chats {
        display: none;
      }

      .show {
        height: 100vh;

        .search,
        .chats {
          display: block;
        }
      }

      .expandMenu {

        .search,
        .chats {
          display: block !important;
        }
      }
    }

    .sidebar {
      flex: 1;
      background-color: $chatNavbarBanner2;
      position: relative;

      .userChat {
        border-bottom: 2px solid $chatNavbarBanner1;
      }

      @include mobile() {}

      .navbar {
        display: flex;
        align-items: center;
        background-color: $chatNavbarBanner1;
        height: 50px;
        padding: 10px;
        justify-content: space-between;
        color: #ddddf7;

        .logo {
          font-weight: bold;

          @include tablet {
            display: none;
          }
        }

        .user {
          display: flex;
          gap: 10px;

          img {
            background-color: #ddddf7;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            object-fit: cover;
          }

          button {
            background-color: $chatNavbarBanner2;
            color: #ddddf7;
            font-size: 10px;
            border: none;
            cursor: pointer;

            @include tablet {
              position: absolute;
              bottom: 10px;
            }
          }
        }
      }

      .search {
        border-bottom: 1px solid gray;

        .searchForm {
          padding: 10px;

          input {
            background-color: transparent;
            border: none;
            color: white;
            outline: none;

            &::placeholder {
              color: lightgray;
            }
          }
        }
      }

      .userChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: white;
        cursor: pointer;

        &:hover {
          background-color: #2f2d52;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }

        .userChatInfo {
          span {
            font-size: 18px;
            font-weight: 500;
          }

          p {
            font-size: 14px;
            color: lightgray;
          }
        }
      }
    }

    .chat {
      flex: 2;
      width: -webkit-fill-available;

      .chatInfo {
        height: 50px;
        background-color: $chatNavbarBanner2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        color: lightgray;
      }

      .chatIcons {
        display: flex;
        gap: 10px;

        img {
          height: 24px;
          cursor: pointer;
        }
      }

      .messages {
        background-color: #ddddf7;
        padding: 10px;
        height: calc(100% - 160px);
        overflow: auto;

        @include mobile() {
          height: calc(100% - 225px);
          margin-top: 65px;
        }

        .message {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .messageInfo {
            display: flex;
            flex-direction: column;
            color: gray;
            font-weight: 300;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .messageContent {
            max-width: 80%;
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
              background-color: white;
              padding: 10px 20px;
              border-radius: 0px 10px 10px 10px;
              max-width: max-content;
            }

            img {
              width: 50%;
            }
          }

          &.owner {
            flex-direction: row-reverse;

            .messageContent {
              align-items: flex-end;

              p {
                background-color: $primary;
                color: white;
                border-radius: 10px 0px 10px 10px;
              }
            }
          }
        }
      }

      .input {
        height: 50px;
        background-color: white;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input {
          width: 100%;
          border: none;
          outline: none;
          color: #2f2d52;
          font-size: 18px;

          &::placeholder {
            color: lightgray;
          }
        }

        .send {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            height: 24px;
            cursor: pointer;
          }

          button {
            border: none;
            padding: 10px 15px;
            color: white;
            background-color: $primary;
            cursor: pointer;

            &:disabled {
              opacity: 0.5 !important;
              background: grey;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.delete-collection {
  position: absolute;
  top: 52px;
  background-color: #cc1717;
  right: 4%;
  color: white;
  border: none;
  border-radius: 10%;
  padding: 0.25rem 0.51rem;
}

@media screen and (max-width: 700px) {
  .delete-collection {
    top: 19px;
    right: 75px;
  }
}